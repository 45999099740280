import { Elements, RichText, RichTextBlock } from 'prismic-reactjs';

import { SYMBOLS } from '@lib/placeholders/constants';
import { APP_ROUTES, DEFAULT_LOCALE } from '@lib/route/constants';
import { replaceRouteParams } from '@lib/route/routeParams';

import { LinkTarget, LinkType, ParsedLink } from './types';

// Manages the url links to internal Prismic documents
export const linkResolver = (doc: LinkType): string => {
  const lang = doc.lang ?? DEFAULT_LOCALE;
  const langPrefix = DEFAULT_LOCALE === lang ? '' : `/${lang}`;

  if (doc.type === 'page') {
    return replaceRouteParams(langPrefix + APP_ROUTES.ROOT_UID.path, {
      uid: doc.uid,
    });
  } else if (doc.type === 'home-page') {
    return langPrefix + APP_ROUTES.HOME.path;
  } else if (doc.type === 'available_products') {
    return langPrefix + APP_ROUTES.PLANS.path;
  } else if (doc.type === 'privacy-policy') {
    return langPrefix + APP_ROUTES.PRIVACY_POLICY.path;
  } else if (doc.type === 'terms-and-conditions') {
    return langPrefix + APP_ROUTES.TERMS_AND_CONDITIONS.path;
  } else if (
    (doc.link_type === 'Web' || doc.link_type === 'Media') &&
    doc?.url
  ) {
    return doc.url;
  }

  return langPrefix + APP_ROUTES.HOME.path;
};

export function isLinkDefined(doc: LinkType): boolean {
  return doc.link_type !== 'Any';
}

export function parseLink(richTextLink?: RichTextBlock[]): ParsedLink {
  let title = SYMBOLS.emptyText;
  let href: string | undefined = undefined;
  let target: LinkTarget | undefined = undefined;

  if (richTextLink) {
    title = RichText.asText(richTextLink);

    const link = richTextLink?.[0]?.spans?.find(
      (span) => span.type === Elements.hyperlink
    )?.data;

    if (link) {
      href = linkResolver(link);
      target = link?.target as LinkTarget;
    }
  }

  return { title, href, target };
}
